<template>
    <div>
        <v-card flat style="background: var(--v-greyRaised-lighten1) !important;">
            <v-toolbar flat dense>
                <v-spacer></v-spacer>
                <v-chip class="mr-1 searchChipWrap" style="max-width:550px">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                icon
                                small
                                left
                                dense
                                color="primary"
                                @click="addItem()">
                                <v-icon small>add_circle_outline</v-icon>
                            </v-btn>
                        </template>
                        <span>Add Charge Item</span>
                    </v-tooltip>
                    <v-icon small color="grey" class="ml-1">search</v-icon>
                    <v-text-field
                        placeholder="Search"
                        class="mb-1"
                        hide-details
                        rounded
                        clearable
                        dense
                        style="width: 300px"
                        v-model="search"
                    ></v-text-field>

                    <v-tooltip top v-if="filterActive">
                    <template v-slot:activator="{ on }">
                        <v-btn
                        rounded
                        right
                        small
                        icon
                        color="redPop"
                        @click="resetFilters"
                        v-on="on"
                        >
                        <v-icon small>filter_alt_off</v-icon>
                        </v-btn>
                    </template>
                    <span style="font-size: 12px">Clear Filters</span>
                    </v-tooltip>
                </v-chip>
            </v-toolbar>
            <ag-grid-vue
            groupDisplayType="multipleColumns"
            :defaultColDef="defaultColumn"
            :rowData="chargeItems"
            :loading="loadingChargeItems"
            :hideOverlay="loadingChargeItems"
            :columnDefs="columns"
            :enableRangeSelection="enableRangeSelection"
            :rowGroupPanelShow="rowGroupPanelShow"
            style="width: auto; height: 83vh"
            :animateRows="false"
            :autoSizeStrategy="{
                type: 'fitCellContents',
                defaultMinWidth: 150,
                columnLimits: [],
            }"
            :sideBar="sideBarDef"
            :rowSelection="'multiple'"
            :getContextMenuItems="getContextMenuItems"
            @grid-ready="onGridReady"
            >

            </ag-grid-vue>
        </v-card>

        <v-dialog v-model="filterDialog" width="750px" :fullscreen="$vuetify.breakpoint.mobile">
            <v-card>
                <v-toolbar flat color="transparent">
                    <v-toolbar-title> Filter</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn text @click="filterDialog = false">X</v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6" class="my-0 py-0">
                            <v-select :items="chargeCategories" clearable v-model="filter.finChargeCategoryId" @change="refreshKey ++"  label="Category*"
                                item-text="category" item-value="id" outlined dense></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" class="my-0 py-0">
                            <v-select :disabled="!filter.finChargeCategoryId" :items="filterChargeSubCategories" @change="refreshKey ++"
                                v-model="filter.finChargeSubCategoryId" clearable label="Subcategory" item-text="subCategory"  item-value="id" outlined dense>
                                
                            </v-select>

                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-subheader>Shipment Type</v-subheader>
                            <v-list-item style="height: 30px">
                                <v-list-item-icon>
                                    <v-icon>arrow_downward</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Import
                                    </v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-switch v-model="filter.isImport"></v-switch>

                                </v-list-item-action>
                            </v-list-item>
                            <v-list-item style="height: 30px">
                                <v-list-item-icon>
                                    <v-icon>arrow_upward</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Export
                                    </v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-switch v-model="filter.isExport"></v-switch>

                                </v-list-item-action>
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-subheader>Load Type</v-subheader>
                            <v-list-item style="height: 30px">
                                <v-list-item-icon>
                                    <v-icon>widgets</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title class="text-wrap">
                                        Full Load (FL)
                                    </v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-switch v-model="filter.fullContainerLoad"></v-switch>
                                </v-list-item-action>
                            </v-list-item>
                            <v-list-item style="height: 30px">
                                <v-list-item-icon>
                                    <v-icon>widgets</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title class="text-wrap">
                                        Less than Load (LL)
                                    </v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-switch v-model="filter.lessContainerLoad"></v-switch>
                                </v-list-item-action>
                            </v-list-item>
                        </v-col>
                        
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="modal" width="900px" persistent :fullscreen="$vuetify.breakpoint.mobile">
            <v-card v-if="item && item.modalType">
                <v-toolbar flat color="transparent" class="my-0 py-0">
                    <v-toolbar-title>
                        {{ item.modalType }} Charge Item
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn :key="validCodeKey" :disabled="!validCode() || (!item.name || !item.finChargeCategoryId)" text icon color="primary"
                        @click="saveChargeItem" :loading="savingChargeItem"><v-icon>save</v-icon></v-btn>
                    <v-btn text @click="modal = false, item = {}">X</v-btn>
                </v-toolbar>
                <v-card-text class="mt-0 pt-0">
                    <v-tabs v-model="chargeTab">
                        <v-tabs-slider color="primary"></v-tabs-slider>
                        <v-tab>Info</v-tab>
                        <v-tab>Origin Countries</v-tab>
                        <v-tab>Destination Countries</v-tab>

                    </v-tabs>
                </v-card-text>

                <v-card-text v-if="chargeTab == 0">
                    <v-row>
                        <v-col cols="12" class="my-0 py-0">
                            <v-divider></v-divider>
                            <v-subheader
                                style="font-size: 16px; font-weight: bold; background: var(--v-greyBase-base)">Charge
                                Type</v-subheader>
                            <v-divider></v-divider>
                        </v-col>
                        <v-col cols="12" sm="4" class="my-0 py-0">
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="text-wrap">
                                        Origin Charge
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="text-wrap" style="font-size: 12px">
                                        Apply this charge item is part of Landside charges
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-switch v-model="item.isOrigin"></v-switch>
                                </v-list-item-action>
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" sm="4" class="my-0 py-0">
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="text-wrap">
                                        Freight Charge
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="text-wrap" style="font-size: 12px">
                                        Apply this charge item as part of freight charges
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-switch v-model="item.isFreight"></v-switch>
                                </v-list-item-action>
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" sm="4" class="my-0 py-0">
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="text-wrap">
                                        Destination Charge
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="text-wrap" style="font-size: 12px">
                                        Apply this charge item as part of destination charges
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-switch v-model="item.isDestination"></v-switch>
                                </v-list-item-action>
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" class="my-0 py-0">
                            <v-divider></v-divider>
                            <v-subheader style="font-size: 16px; font-weight: bold; background: var(--v-greyBase-base)">Mode
                                of Transport</v-subheader>
                            <v-divider></v-divider>
                        </v-col>
                        <v-col cols="12">
                            <v-row justify="center">
                                <v-col cols="12" sm="3" class="mt-0 pt-0">
                                    <v-list-item style="border-right: 0.5px solid grey">
                                        <v-list-item-icon>
                                            <v-icon>directions_boat</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Sea
                                            </v-list-item-title>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                            <v-switch v-model="item.isSea"></v-switch>

                                        </v-list-item-action>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="12" sm="3" class="mt-0 pt-0">
                                    <v-list-item style="border-right: 0.5px solid grey">
                                        <v-list-item-icon>
                                            <v-icon>flight</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Air
                                            </v-list-item-title>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                            <v-switch v-model="item.isAir"></v-switch>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="12" sm="3" class="mt-0 pt-0">
                                    <v-list-item style="border-right: 0.5px solid grey">
                                        <v-list-item-icon>
                                            <v-icon>train</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Rail
                                            </v-list-item-title>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                            <v-switch v-model="item.isRail"></v-switch>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="12" sm="3" class="mt-0 pt-0">
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon>local_shipping</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Road
                                            </v-list-item-title>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                            <v-switch v-model="item.isRoad"></v-switch>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" sm="6" class="my-0 py-0">
                            <v-select :items="chargeCategories" v-model="item.finChargeCategoryId" :key="editKey"
                                @change="item.finChargeSubCategoryId = null, editKey++" label="Category*"
                                item-text="category" item-value="id" outlined dense>
                                <!-- <template v-slot:append-item>
                                    <v-divider class="mb-2"></v-divider>
                                    <v-list-item style="height: 30px" @click="addCategory()">
                                        <v-list-item-content class="text-center">
                                            <v-list-item-title class="text-center">
                                                <v-btn color="primary" small text>Add Category</v-btn>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template> -->
                            </v-select>
                        </v-col>
                        <v-col cols="12" sm="6" class="my-0 py-0">
                            <v-select :disabled="!item.finChargeCategoryId" :items="chargeSubCategories" :key="editKey"
                                v-model="item.finChargeSubCategoryId" label="Subcategory" item-text="subCategory"
                                @change="editKey++" item-value="id" outlined dense>
                                <!-- <template v-slot:append-item>
                                    <v-divider class="mb-2"></v-divider>
                                    <v-list-item style="height: 30px" @click="addSubCategory()">
                                        <v-list-item-content class="text-center">
                                            <v-list-item-title class="text-center">
                                                <v-btn small color="primary" text>Add Subcategory</v-btn>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template> -->
                            </v-select>

                        </v-col>
                        <v-col cols="12" sm="6" class="my-0 py-0">
                            <v-text-field :disabled="item.id != null && item.id != undefined" label="Code"
                                :rules="codeRules" v-model="item.orgCode" clearable outlined dense @change="validCodeKey ++"></v-text-field>
                            <v-text-field label="Name*" v-model="item.name" clearable outlined dense></v-text-field>
                            <!-- <v-select label="Base Type*" outlined dense clearable :items="baseTypes" item-text="name"
                                item-value="value" v-model="item.baseType"></v-select> -->
                        </v-col>
                        <v-col cols="12" sm="6" class="my-0 py-0">
                            <v-textarea rows="4" label="Description" v-model="item.description" clearable outlined
                                dense></v-textarea>
                        </v-col>
                        <v-col cols="12" sm="6" class="my-0 py-0">
                            <!-- <v-divider></v-divider> -->
                            <v-subheader class="my-0 py-0"
                                style="font-size: 16px; font-weight: bold; background: var(--v-greyBase-base)">Shipment
                                Type</v-subheader>

                            <!-- <v-divider></v-divider> -->
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Import Charge
                                    </v-list-item-title>
                                    <v-list-item-subtitle style="font-size: 12px">
                                        Apply this charge item to import shipments
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-switch v-model="item.isImport"></v-switch>
                                </v-list-item-action>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Export Charge
                                    </v-list-item-title>
                                    <v-list-item-subtitle style="font-size: 12px">
                                        Apply this charge item to export shipments
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-switch v-model="item.isExport"></v-switch>
                                </v-list-item-action>
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" sm="6" class="my-0 py-0">
                            <v-divider></v-divider>
                            <v-subheader style="font-size: 16px; font-weight: bold; background: var(--v-greyBase-base)">Load
                                Type</v-subheader>

                            <v-divider></v-divider>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Less than Load (LL)
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-switch v-model="item.lessContainerLoad"></v-switch>
                                </v-list-item-action>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Full Load (FL)
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-switch v-model="item.fullContainerLoad"></v-switch>
                                </v-list-item-action>
                            </v-list-item>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-text v-else-if="chargeTab == 1">
                    <v-row justify="center">
                        <v-col cols="12" sm="12" md="6">
                            <v-list dense subheader>
                                <v-subheader style="background: var(--v-component-base); font-size: 16px">Origin Regions
                                </v-subheader>
                                <v-subheader>
                                    <v-spacer></v-spacer>
                                    <v-chip small outlined color="success" class="mr-1" v-if="!showOnlySelectedOriginRegion"
                                        @click="showOnlySelectedOriginRegion = true">
                                        <v-icon right class="mr-1" small>check</v-icon>
                                        Show Selected</v-chip>
                                    <v-chip small outlined color="primary" class="mr-1" v-if="showOnlySelectedOriginRegion"
                                        @click="showOnlySelectedOriginRegion = false">
                                        <v-icon right class="mr-1" small>list</v-icon>
                                        Show All</v-chip>
                                    <v-chip outlined color="blue" v-if="!item.allOriginRegion" small
                                        @click="(item.allOriginRegion = true), (selectedOriginRegions = [])">Select
                                        All</v-chip>
                                    <v-chip outlined color="danger" v-else small
                                        @click="(item.allOriginRegion = false), (selectedOriginRegions = [])">Deselect
                                        All</v-chip>
                                </v-subheader>
                                <v-text-field class="mt-2 px-2" prepend-inner-icon="search" outlined dense label="Search"
                                    v-model="searchOriginRegion" clearable></v-text-field>
                                <v-list dense style="max-height: 35vh; overflow-y: auto" :key="countryKey">
                                    <v-list-item v-for="region in filterOriginRegions" :key="region.id">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ region.name }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle style="font-size: 12px" v-if="region.countryRegions">
                                                {{ region.countryRegions.length }} Countries
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action :key="countryKey">
                                            <v-icon v-if="item.allOriginRegion" color="success">check</v-icon>
                                            <v-checkbox v-if="!item.allOriginRegion" v-model="selectedOriginRegions"
                                                color="primary" :value="region.id"></v-checkbox>
                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item v-if="filterOriginRegions && filterOriginRegions.length == 0">
                                        <v-list-item-content>
                                            <span style="color: grey">No regions found.</span>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-list>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                            <v-list subheader dense :key="countryKey">
                                <v-subheader style="background: var(--v-component-base); font-size: 16px">Origin
                                    Countries
                                </v-subheader>

                                <v-subheader>
                                    <v-spacer></v-spacer>
                                    <v-chip small outlined color="success" class="mr-1"
                                        v-if="!showOnlySelectedOriginCountry" @click="showOnlySelectedOriginCountry = true">
                                        <v-icon right class="mr-1" small>check</v-icon>
                                        Show Selected</v-chip>
                                    <v-chip small outlined color="primary" class="mr-1" v-if="showOnlySelectedOriginCountry"
                                        @click="showOnlySelectedOriginCountry = false">
                                        <v-icon right class="mr-1" small>list</v-icon>
                                        Show All</v-chip>
                                    <v-chip v-if="!item.allOriginCountry" small outlined color="blue"
                                        @click="(item.allOriginCountry = true), (selectedOriginCountries = [])">Select All
                                        Countries
                                    </v-chip>
                                    <v-chip v-else small outlined color="danger"
                                        @click="(item.allOriginCountry = false), (selectedOriginCountries = [])">Deselect
                                        All
                                        Countries</v-chip>
                                </v-subheader>
                                <v-row>
                                    <v-col cols="8" class="mx-0 px-0">
                                        <v-text-field class="mt-2 px-2" prepend-inner-icon="search" outlined dense
                                            label="Search" v-model="searchOriginCountry" clearable></v-text-field>
                                    </v-col>
                                    <v-col cols="4" class="mx-0 px-0">
                                        <v-autocomplete clearable dense prepend-inner-icon="filter_alt" label="Filter"
                                            outlined class="mt-2" v-model="filterOriginRegion" :items="regions"
                                            item-text="name" item-value="id"></v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-list dense style="height: 35vh; overflow-y: auto" class="mt-0 pt-0">
                                    <v-list-item style="height: 25px" v-for="country in filterOriginCountries"
                                        :key="country.id">
                                        <v-list-item-avatar>
                                            <v-img contain v-if="country.iso2"
                                                :src="`https://cdn.loglive.io/flags/4x3/${country.iso2.toLowerCase()}.svg`"></v-img>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ country.name }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                {{ country.regionCode }}
                                                <span v-if="originRegionSelected(country.regionCode)"
                                                    style="color: var(--v-success-base)"><i>(Region Selected)</i></span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-checkbox v-if="!item.allOriginCountry" v-model="selectedOriginCountries"
                                                color="primary" :value="country.id"></v-checkbox>
                                            <v-icon v-else color="success">check</v-icon>
                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item v-if="filterOriginCountries && filterOriginCountries.length == 0">
                                        <v-list-item-content>
                                            <span style="color: grey">No countries found.</span>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-text v-else-if="chargeTab == 2">
                    <v-row justify="center">
                        <v-col cols="12" sm="12" md="6">
                            <v-list dense subheader>
                                <v-subheader style="background: var(--v-component-base); font-size: 16px">Destination
                                    Regions
                                </v-subheader>
                                <v-subheader>
                                    <v-spacer></v-spacer>
                                    <v-chip small outlined color="success" class="mr-1"
                                        v-if="!showOnlySelectedDestinationRegion"
                                        @click="showOnlySelectedDestinationRegion = true">
                                        <v-icon right class="mr-1" small>check</v-icon>
                                        Show Selected</v-chip>
                                    <v-chip small outlined color="primary" class="mr-1"
                                        v-if="showOnlySelectedDestinationRegion"
                                        @click="showOnlySelectedDestinationRegion = false">
                                        <v-icon right class="mr-1" small>list</v-icon>
                                        Show All</v-chip>
                                    <v-chip outlined color="blue" v-if="!item.allDestinationRegion" small
                                        @click="(item.allDestinationRegion = true), (selectedDestinationRegions = [])">Select
                                        All</v-chip>
                                    <v-chip outlined color="danger" v-else small
                                        @click="(item.allDestinationRegion = false), (selectedDestinationRegions = [])">Deselect
                                        All</v-chip>
                                </v-subheader>
                                <v-text-field class="mt-2 px-2" prepend-inner-icon="search" outlined dense label="Search"
                                    v-model="searchDestinationRegion" clearable></v-text-field>
                                <v-list dense style="max-height: 35vh; overflow-y: auto" :key="countryKey">
                                    <v-list-item v-for="region in filterDestinationRegions" :key="region.id">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ region.name }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle style="font-size: 12px" v-if="region.countryRegions">
                                                {{ region.countryRegions.length }} Countries
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action :key="countryKey">
                                            <v-icon v-if="item.allDestinationRegion" color="success">check</v-icon>
                                            <v-checkbox v-if="!item.allDestinationRegion"
                                                v-model="selectedDestinationRegions" color="primary"
                                                :value="region.id"></v-checkbox>
                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item v-if="filterDestinationRegions && filterDestinationRegions.length == 0">
                                        <v-list-item-content>
                                            <span style="color: grey">No regions found.</span>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-list>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                            <v-list subheader dense :key="countryKey">
                                <v-subheader style="background: var(--v-component-base); font-size: 16px">Destination
                                    Countries
                                </v-subheader>

                                <v-subheader>
                                    <v-spacer></v-spacer>
                                    <v-chip small outlined color="success" class="mr-1"
                                        v-if="!showOnlySelectedDestinationCountry"
                                        @click="showOnlySelectedDestinationCountry = true">
                                        <v-icon right class="mr-1" small>check</v-icon>
                                        Show Selected</v-chip>
                                    <v-chip small outlined color="primary" class="mr-1"
                                        v-if="showOnlySelectedDestinationCountry"
                                        @click="showOnlySelectedDestinationCountry = false">
                                        <v-icon right class="mr-1" small>list</v-icon>
                                        Show All</v-chip>
                                    <v-chip v-if="!item.allDestinationCountry" small outlined color="blue"
                                        @click="(item.allDestinationCountry = true), (selectedDestinationCountries = [])">Select
                                        All
                                        Countries
                                    </v-chip>
                                    <v-chip v-else small outlined color="danger"
                                        @click="(item.allDestinationCountry = false), (selectedDestinationCountries = [])">Deselect
                                        All
                                        Countries</v-chip>
                                </v-subheader>
                                <v-row>
                                    <v-col cols="8" class="mx-0 px-0">
                                        <v-text-field class="mt-2 px-2" prepend-inner-icon="search" outlined dense
                                            label="Search" v-model="searchDestinationCountry" clearable></v-text-field>
                                    </v-col>
                                    <v-col cols="4" class="mx-0 px-0">
                                        <v-autocomplete clearable dense prepend-inner-icon="filter_alt" label="Filter"
                                            outlined class="mt-2" v-model="filterDestinationRegion" :items="regions"
                                            item-text="name" item-value="id"></v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-list dense style="height: 35vh; overflow-y: auto" class="mt-0 pt-0">
                                    <v-list-item style="height: 25px" v-for="country in filterDestinationCountries"
                                        :key="country.id">
                                        <v-list-item-avatar>
                                            <v-img contain v-if="country.iso2"
                                                :src="`https://cdn.loglive.io/flags/4x3/${country.iso2.toLowerCase()}.svg`"></v-img>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ country.name }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                {{ country.regionCode }}
                                                <span v-if="destinationRegionSelected(country.regionCode)"
                                                    style="color: var(--v-success-base)"><i>(Region Selected)</i></span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-checkbox v-if="!item.allDestinationCountry"
                                                v-model="selectedDestinationCountries" color="primary"
                                                :value="country.id"></v-checkbox>
                                            <v-icon v-else color="success">check</v-icon>
                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item
                                        v-if="filterDestinationCountries && filterDestinationCountries.length == 0">
                                        <v-list-item-content>
                                            <span style="color: grey">No countries found.</span>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { AgGridVue } from "ag-grid-vue";
/* eslint-disable vue/no-unused-components */
import ChargeDetails from "@/components/GridComponents/ChargeDetails.vue";

export default {
    components: {
        AgGridVue,
        ChargeDetails
    },
  data() {
    return {
        defaultColumn: {
            sortable: true,
            // filter: 'agMultiColumnFilter',
            enableRowGroup: true,
            cellClass: 'd-flex align-center',
            // cellRenderer: 'ChargeDetails',
            // enablePivot: true,
        },
        rowGroupPanelShow: 'always',
        enableRangeSelection: true,
        sideBarDef: {
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                },
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                },
            ],
            defaultToolPanel: '',
        },
        chargeCategories: [],
        chargeItems: [],
        search: null,
        refreshKey: 0,
        loadingChargeItems: false,
        newBillingItem: {},
        categoryItem: {},
        categoryModal: false,
        chargeTab: 0,
        codeRules: [
            (v) =>
                (v && v.length <= 10) ||
                "Code can be a maximum of 10 characters",
        ],
        filter: {},
        countries: [],
        regions: [],
        filterDialog: false,
        item: {},
        modal: false,
        subcategoryModal: false,
        savingChargeItem: false,
        selectedOriginRegions: [],
        selectedDestinationCountries: [],
        selectedOriginCountries: [],
        selectedDestinationRegions: [],
        editKey: 0,
        validCodeKey: 5000,
        searchOriginRegion: null,
        searchDestinationRegion: null,
        searchOriginCountry: null,
        searchDestinationCountry: null,
        showOnlySelectedOriginRegion: false,
        showOnlySelectedDestinationRegion: false,
        showOnlySelectedOriginCountry: false,
        showOnlySelectedDestinationCountry: false,
        filterOriginRegion: null,
        filterDestinationRegion: null,
        countryKey: 0,
        columns: [
            {
                headerName: 'Code',
                field: 'orgCode', 
                filter: 'agSetColumnFilter',
                filterParams: {
                    applyMiniFilterWhileTyping: true,
                },
            },
            {
                headerName: 'Name',
                field: 'name', 
                filter: 'agSetColumnFilter',
                filterParams: {
                    applyMiniFilterWhileTyping: true,
                },
                // filterParams: {
                //     textFormatter: value => {
                //         return value
                //             .replace(/\s/g, '')
                //             .replace(/[àáâãäå]/g, 'a')
                //             .replace(/æ/g, 'ae')
                //             .replace(/ç/g, 'c')
                //             .replace(/[èéêë]/g, 'e')
                //             .replace(/[ìíîï]/g, 'i')
                //             .replace(/ñ/g, 'n')
                //             .replace(/[òóôõö]/g, 'o')
                //             .replace(/œ/g, 'oe')
                //             .replace(/[ùúûü]/g, 'u')
                //             .replace(/[ýÿ]/g, 'y')
                //             .replace(/\W/g, '');
                //     }
                // }
            },
            {
                headerName: 'Category',
                field: 'finChargeCategory.category',
                filter: 'agSetColumnFilter',
                filterParams: {
                    applyMiniFilterWhileTyping: true,
                },
            },
            {
                headerName: 'Subcategory',
                field: 'finChargeSubCategory.subCategory',
                filter: 'agSetColumnFilter',
                filterParams: {
                    applyMiniFilterWhileTyping: true,
                },
            },
            {
                headerName: 'Shipment Type',
                // field: (params) => {
                //     if(params.data && params.data.isImport && params.data.isExport) {
                //         return 'Import/Export';
                //     } else if(params.data && params.data.isImport) {
                //         return 'Import';
                //     } else if(params.data && params.data.isExport) {
                //         return 'Export';
                //     } else {
                //         return 'Unspecified'
                //     }
                // }, 
                field: 'isImport',
                cellClass: 'd-flex align-center justify-center',
                cellRenderer: 'ChargeDetails',
                cellRendererParams: (params) => {
                    return {
                        type: 'importExport',
                        details: {
                            isImport: params.data ? params.data.isImport : false,
                            isExport: params.data ? params.data.isExport : false,
                        }
                    }
                }
                // (p) => {
                // return {
                //         type: 'importExport',
                //         isImport: p.data ? p.data.isImport : false,
                //         isExport: p.data ? p.data.isExport : false,
                //     }
                // },
                
            },

            {
                headerName: 'Charge Type',
                field: 'chargeLocation',
                cellRenderer: 'ChargeDetails',
                cellClass: 'd-flex align-center justify-center',
                cellRendererParams: (params) => {
                    return {
                        type: 'chargeLocation',
                        details: {
                            isOrigin: params.data ? params.data.isOrigin : false,
                            isFreight: params.data ? params.data.isFreight : false,
                            isDestination: params.data ? params.data.isDestination : false,
                        }
                    }
                }
            },
            {
                headerName: 'Mode',
                filter: false,
                cellClass: 'd-flex align-center justify-center',
                cellRenderer: 'ChargeDetails',
                cellRendererParams: (params) => {
                    return {
                        type: 'mode',
                        details: {
                            isSea: params.data ? params.data.isSea : false,
                            isAir: params.data ? params.data.isAir : false,
                            isRail: params.data ? params.data.isRail : false,
                            isRoad: params.data ? params.data.isRoad : false,
                        }
                    };
                },
                
            },
            // {
            //     headerName: 'Mode',
            //     cellClass: 'd-flex align-center justify-center',
            //     cellRenderer: 'ChargeDetails',
            //     cellRendererParams: (params) => {
            //         return {
            //             type: 'mode',
            //             details: {
            //                 isSea: params.data ? params.data.isSea : false,
            //                 isAir: params.data ? params.data.isAir : false,
            //                 isRail: params.data ? params.data.isRail : false,
            //                 isRoad: params.data ? params.data.isRoad : false,
            //             }
            //         };
            //     },
            //     valueGetter: (params) => {
            //         const { isSea, isAir, isRail, isRoad } = params.data;
            //         const modes = [];
            //         if (isSea) modes.push('Sea');
            //         if (isAir) modes.push('Air');
            //         if (isRail) modes.push('Rail');
            //         if (isRoad) modes.push('Road');
            //         return modes.join(', '); // For display purposes only
            //     },
            //     filter: 'agSetColumnFilter', // Set filter allows multi-select
            //     filterParams: {
            //         values: ['Sea', 'Air', 'Rail', 'Road'], // Options in the filter dropdown
            //         suppressAndOrCondition: true,
            //         filterFunction: (selectedModes, cellValue, rowData) => {
            //             const { isSea, isAir, isRail, isRoad } = rowData; // Use boolean fields for filtering
            //             const modeMap = {
            //                 Sea: isSea,
            //                 Air: isAir,
            //                 Rail: isRail,
            //                 Road: isRoad
            //             };

            //             // Check if any of the selected modes are true for this row
            //             return selectedModes.some(mode => modeMap[mode]);
            //         }
            //     }
            // },
            {
                headerName: 'Load Type',
                field: 'loadType',
                cellClass: 'd-flex align-center justify-center',
                cellRenderer: 'ChargeDetails',
                cellRendererParams: (params) => {
                    return {
                        type: 'loadType',
                        details: {
                            fullContainerLoad: params.data ? params.data.fullContainerLoad : false,
                            lessContainerLoad: params.data ? params.data.lessContainerLoad : false,
                        }
                    }
                }
            },

            {
                headerName: 'Region Specific',
                field: 'region',
                cellClass: 'd-flex align-center justify-center',
                cellRenderer: 'ChargeDetails',
                cellRendererParams: (params) => {
                    return {
                        type: 'region',
                        details: params.data,
                        regions: this.regions
                    }
                }
            },
            {
                headerName: 'Country Specific',
                field: 'country',
                cellClass: 'd-flex align-center justify-center',
                cellRenderer: 'ChargeDetails',
                cellRendererParams: (params) => {
                    return {
                        type: 'country',
                        details: params.data,
                        countries: this.countries
                    }
                }
            },

            {
                headerName: 'Created By',
                field: 'createdBy.firstname', 
                cellRenderer: 'ChargeDetails',
                cellClass: 'd-flex align-center justify-left',
                cellRendererParams: (params) => {
                    return {
                        type: 'createdBy',
                        details: params.data ? params.data.createdBy : {}
                    }
                }
            },
            {
                headerName: 'Updated By',
                field: 'updatedBy.firstname', 
                cellRenderer: 'ChargeDetails',
                cellClass: 'd-flex align-center justify-left',
                cellRendererParams: (params) => {
                    return {
                        type: 'updatedBy',
                        details: params.data ? params.data.updatedBy : {}
                    }
                }
            },
            
        ],

        gridApi: null,
        gridLoaded: false,
        filterActive: false,
        filterSetting: null,
        searchTimeout: null,

    }
    
        
  },
  async created() {
    // this.getChargeItems()
    // this.getFinanceCategories()
    // this.regions = await this.$API.getRegions();
    //     this.countries = [].concat.apply(
    //         [],
    //         this.regions.map((x) => x.countryRegions)
    //     );
  },
  watch: {
    search(val) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(async () => {
        this.gridApi.updateGridOptions({ quickFilterText: val });
      }, 400);
    },
  },
  computed: {
    filterOriginCountries() {
            let result = [];
            let regions = this.regions;
            if (this.filterOriginRegion) {
                regions = regions.filter((x) => x.id == this.filterOriginRegion);
            }
            result = [].concat.apply(
                [],
                regions.map((x) => x.countryRegions)
            );
            result.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
            if (this.searchOriginCountry) {
                result = result.filter((x) =>
                    x.name.toLowerCase().includes(this.searchOriginCountry.toLowerCase())
                );
            }
            if (this.showOnlySelectedOriginCountry && !this.item.allOriginCountry) {
                result = result.filter((x) => this.selectedOriginCountries.includes(x.id));
            }
            return result;
        },
        filterDestinationCountries() {
            let result = [];
            let regions = this.regions;
            if (this.filterDestinationRegion) {
                regions = regions.filter((x) => x.id == this.filterDestinationRegion);
            }
            result = [].concat.apply(
                [],
                regions.map((x) => x.countryRegions)
            );
            result.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
            if (this.searchDestinationCountry) {
                result = result.filter((x) =>
                    x.name.toLowerCase().includes(this.searchDestinationCountry.toLowerCase())
                );
            }
            if (this.showOnlySelectedDestinationCountry && !this.item.allDestinationCountry) {
                result = result.filter((x) => this.selectedDestinationCountries.includes(x.id));
            }
            return result;
        },
        filterOriginRegions() {
            let result = this.regions;
            if (this.showOnlySelectedOriginRegion && !this.item.allOriginRegion) {
                result = result.filter((x) => this.selectedOriginRegions.includes(x.id));
            }
            if (this.searchOriginRegion) {
                result = result.filter((x) =>
                    x.name.toLowerCase().includes(this.searchOriginRegion.toLowerCase())
                );
            }
            return result;
        },
        filterDestinationRegions() {
            let result = this.regions;
            if (this.showOnlySelectedDestinationRegion && !this.item.allDestinationRegion) {
                result = result.filter((x) => this.selectedDestinationRegions.includes(x.id));
            }
            if (this.searchDestinationRegion) {
                result = result.filter((x) =>
                    x.name.toLowerCase().includes(this.searchDestinationRegion.toLowerCase())
                );
            }
            return result;
        },
        chargeSubCategories() {
            let result = []
            if (this.item.finChargeCategoryId) {
                result = this.chargeCategories.find(c => c.id === this.item.finChargeCategoryId).finChargeSubCategories
            }
            return result
        },
        filterChargeSubCategories() {
            let result = []
            if (this.filter.finChargeCategoryId) {
                result = this.chargeCategories.find(c => c.id === this.filter.finChargeCategoryId).finChargeSubCategories
            }
            return result
        },
  },
  async onBeforeMount() {
    await this.getChargeItems()
  },
  methods: { 
    async resetFilters() {
      this.gridApi.setFilterModel({});
    },
    originRegionSelected(code) {
        let find = this.regions.find((x) => x.code == code);
        return find ? this.selectedOriginRegions.includes(find.id) : false;
    },
    destinationRegionSelected(code) {
        let find = this.regions.find((x) => x.code == code);
        return find ? this.selectedDestinationRegions.includes(find.id) : false;
    },
    filterListener(event) {
      let filter = this.gridApi.getFilterModel();
      this.filterActive = Object.keys(filter).length > 0;
      this.filterSetting = filter;
    },
    async onGridReady(params) {
      this.gridApi = params.api;  
      this.gridApi.addEventListener("filterChanged", this.filterListener);
      this.getChargeItems()
      this.getFinanceCategories()
        this.regions = await this.$API.getRegions();
            this.countries = [].concat.apply(
                [],
                this.regions.map((x) => x.countryRegions)
            );
    //   this.gridLoaded = true
    },
    getContextMenuItems(params) {
        // console.log(params)
            let result = [];
            // if (!params.node.group) {
                result = [
                  {
                    name: "Edit Item",
                    icon:'<span class="material-icons" unselectable="on" role="presentation" style="font-size: 16px; color: white; opacity: 0.65; margin-bottom: 0px; padding-bottom: 0px">launch</span>',
                    action: () => {
                      this.editItem(params, 'item');
                    },
                  },
                  'separator',
                  //   'cut',
                  'copy',
                  {
                    name: "Clone Item",
                    icon:'<span class="material-icons" unselectable="on" role="presentation" style="font-size: 16px; color: white; opacity: 0.65; margin-bottom: 0px; padding-bottom: 0px">content_copy</span>',
                    action: () => {
                      this.duplicateItem(params, 'item');
                    },
                  },
                  //   'copyWithHeaders',
                  //   'copyWithGroupHeaders',
                  //   'paste',
                  {
                    name: "Delete Item",
                    icon:'<span class="material-icons" unselectable="on" role="presentation" style="font-size: 16px; color: white; opacity: 0.65; margin-bottom: 0px; padding-bottom: 0px">launch</span>',
                    action: () => {
                      this.deleteItem(params, 'item');
                    },
                  },
                  'separator',
                  'autoSizeAll',
                //   'expandAll',
                //   'contractAll',
                //   'resetColumns',
                //   'separator',
                //   'chartRange',
                  'export',
                ];
                
                return result;
            },
    async getChargeItems() {
            this.loadingChargeItems = true

            this.chargeItems = await this.$API.getOrgFinanceChargeItems()
            this.loadingChargeItems = false
        },
        // originRegionSelected(code) {
        //     let find = this.regions.find((x) => x.code == code);
        //     return find ? this.selectedOriginRegions.includes(find.id) : false;
        // },
        // destinationRegionSelected(code) {
        //     let find = this.regions.find((x) => x.code == code);
        //     return find ? this.selectedDestinationRegions.includes(find.id) : false;
        // },
        deleteItem(item, type) {
            
            if(item.node.data) {
                item = {...item.node.data}
                
                this.loadingChargeItems = true
                this.$confirm("Are you sure you want to delete this charge item?", "Confirm", {
                    center: true,
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    type: "plain",
                })
                    .then(async () => {
                        if (item.id) {
                            await this.$API.updateOrgFinanceChargeItem({
                                id: item.id,
                                isActive: false,
                                isDeleted: true,
                            });
                        }
                        let index = this.chargeItems.indexOf(item)
                        this.chargeItems.splice(index, 1);
                        this.$message({
                            type: "success",
                            message: "Successfully deleted!",
                        });
                        this.loadingChargeItems = false
                    })
                    .catch(() => {
                        this.loadingChargeItems = false
                    });
                    this.savingChargeItem = false
                    this.getChargeItems()
                    this.refreshKey++
            } else {
                this.$message({
                    type: "error",
                    message: "Unable to delete item",
                })
                console.log('No data')
            }
        },
        // computeFilterCount() {
        //     let count = 0
        //     let keys = Object.keys(this.filter)
        //     for (let i = 0; i < keys.length; i++) {
        //         if (this.filter[keys[i]]) {
        //             count++
        //         }
        //     }
        //     return count
        // },
        // filteredChargeItems() {
        //     let result = this.chargeItems
        //     if (this.filter) {
        //         let keys = Object.keys(this.filter)
        //         let filter = keys.filter(x => this.filter[x])
        //         result = result.filter(x => filter.every(y => x[y] == this.filter[y]))
        //     }
        //     return result
        // },
        async saveCountryRegions(item) {
            let existingOriginRegions = item.finChargeItemAreas.filter(x => x.type == 'Origin' && x.regionId)
            let existingDestinationRegions = item.finChargeItemAreas.filter(x => x.type == 'Destination' && x.regionId)
            let existingOriginCountries = item.finChargeItemAreas.filter(x => x.type == 'Origin' && x.countryRegionId)
            let existingDestinationCountries = item.finChargeItemAreas.filter(x => x.type == 'Destination' && x.countryRegionId)

            let originRegionsToAdd = this.selectedOriginRegions.filter(x => !existingOriginRegions.map(y => y.regionId).includes(x))
            let destinationRegionsToAdd = this.selectedDestinationRegions.filter(x => !existingDestinationRegions.map(y => y.regionId).includes(x))
            let originCountriesToAdd = this.selectedOriginCountries.filter(x => !existingOriginCountries.map(y => y.countryRegionId).includes(x))
            let destinationCountriesToAdd = this.selectedDestinationCountries.filter(x => !existingDestinationCountries.map(y => y.countryRegionId).includes(x))

            let create = [
                ...originRegionsToAdd.map(x => ({ type: 'Origin', regionId: x, finChargeItemId: item.id })),
                ...destinationRegionsToAdd.map(x => ({ type: 'Destination', regionId: x, finChargeItemId: item.id })),
                ...originCountriesToAdd.map(x => ({ type: 'Origin', countryRegionId: x, finChargeItemId: item.id })),
                ...destinationCountriesToAdd.map(x => ({ type: 'Destination', countryRegionId: x, finChargeItemId: item.id }))
            ]
            let originRegionsToDelete = existingOriginRegions.filter(x => !this.selectedOriginRegions.includes(x.regionId))
            let destinationRegionsToDelete = existingDestinationRegions.filter(x => !this.selectedDestinationRegions.includes(x.regionId))
            let originCountriesToDelete = existingOriginCountries.filter(x => !this.selectedOriginCountries.includes(x.countryRegionId))
            let destinationCountriesToDelete = existingDestinationCountries.filter(x => !this.selectedDestinationCountries.includes(x.countryRegionId))
            let deleteIds = [
                ...originRegionsToDelete.map(x => x.id),
                ...destinationRegionsToDelete.map(x => x.id),
                ...originCountriesToDelete.map(x => x.id),
                ...destinationCountriesToDelete.map(x => x.id)
            ]
            deleteIds = deleteIds.map(x => ({ id: x, isActive: false, isDeleted: true }))
            let batch = [...create, ...deleteIds]
            await this.$API.createOrgFinanceChargeItemArea({ batch })
        },
        async getFinanceCategories() {
            try {
                this.chargeCategories = await this.$API.getAllOrgFinanceCategories()
            } catch (e) {
                this.$message.error(e.message)
            }
        },
        async saveChargeItem() {
            this.savingChargeItem = true
            try {
                let result
                if (!this.item.id) {
                    let result = await this.$API.createOrgFinanceChargeItem(this.item)
                    this.chargeItems.push(result)
                    this.item.id = result.id
                } else {
                    let result = await this.$API.updateOrgFinanceChargeItem(this.item)
                    let index = this.chargeItems.findIndex(x => x.id == this.item.id)
                    this.chargeItems[index] = result
                }
                await this.saveCountryRegions(this.item)
                this.$message.success('Charge Item saved successfully')

                this.modal = false
                this.item = {}
            } catch (e) {
                this.$message.error(e.message)
                console.log(e)
            } finally {
                this.savingChargeItem = false
                this.getChargeItems()
                this.refreshKey++

            }
        },
        validCode(){
          if(this.item.code && this.item.code.length >=5 && !this.chargeItems.find(x=>x.code == this.item.code && x.id != this.item.id)){
            return true
          } else if(!this.item.code){
            return true
          } else {
            return false
          }
        },
        addItem() {
            this.item = {
                allOriginRegion: true,
                allOriginCountry: true,
                allDestinationRegion: true,
                allDestinationCountry: true,
                finChargeItemAreas: []
            }
            this.item.modalType = 'Add'
            this.selectedOriginRegions = []
            this.selectedDestinationRegions = []
            this.selectedOriginCountries = []
            this.selectedDestinationCountries = []
            this.modal = true
        },
        formattedRegions(type, item) {
            let regions = item.finChargeItemAreas.filter(x => x.type == type && x.regionId).map(x => x.regionId)
            return this.regions.filter(x => regions.includes(x.id)).map(x => x.name).length > 0 ? this.regions.filter(x => regions.includes(x.id)).map(x => x.name).length : null
        },
        formattedCountries(type, item) {
            let countries = item.finChargeItemAreas.filter(x => x.type == type && x.countryRegionId).map(x => x.countryRegionId)
            return this.countries.filter(x => countries.includes(x.id)).map(x => x.name).length > 0 ? this.countries.filter(x => countries.includes(x.id)).map(x => x.name).length : null
        },
        logItem(item) {
            if(item.node.data) {
                console.log(item.node.data)
            } else {

                console.log(item)
            }
        },
    editItem(data) {
        let item
        if(data.node.data) {
            item = {...data.node.data}
            this.item = item
            this.item.modalType = 'Edit'
            this.selectedOriginRegions = this.item.finChargeItemAreas.filter(x => x.type == 'Origin' && x.regionId).map(x => x.regionId)
            this.selectedDestinationRegions = this.item.finChargeItemAreas.filter(x => x.type == 'Destination' && x.regionId).map(x => x.regionId)
            this.selectedOriginCountries = this.item.finChargeItemAreas.filter(x => x.type == 'Origin' && x.countryRegionId).map(x => x.countryRegionId)
            this.selectedDestinationCountries = this.item.finChargeItemAreas.filter(x => x.type == 'Destination' && x.countryRegionId).map(x => x.countryRegionId)
            this.modal = true
        } else {
            console.log('No data')
        }
        },
        duplicateItem(data) {
        let item
        if(data.node.data) {
            item = {...data.node.data}
            this.item = item
            delete this.item.id
            delete this.item.code
            delete this.item.createdById
             this.item.editedById = null
            this.item.modalType = 'Add'
            this.selectedOriginRegions = this.item.finChargeItemAreas.filter(x => x.type == 'Origin' && x.regionId).map(x => x.regionId)
            this.selectedDestinationRegions = this.item.finChargeItemAreas.filter(x => x.type == 'Destination' && x.regionId).map(x => x.regionId)
            this.selectedOriginCountries = this.item.finChargeItemAreas.filter(x => x.type == 'Origin' && x.countryRegionId).map(x => x.countryRegionId)
            this.selectedDestinationCountries = this.item.finChargeItemAreas.filter(x => x.type == 'Destination' && x.countryRegionId).map(x => x.countryRegionId)
            this.modal = true
        } else {
            console.log('No data')
        }
        },
  }
}
</script>
<style scoped>
.searchChipWrap {
  width: 70%;
  display: grid;
  transition: width 0.3s ease-in-out;
}
</style>